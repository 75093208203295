import "./cv.scss"
//import ActionButton from 'react-native-action-button';

export default function Cv() {
  return (
    <div className="divCV" id="divCV" >
        {/*<div className="divbut">            
            <a href="https://drive.google.com/file/d/1aZbSZ-lXVx-RpugRS2tkuOWkFKLb3b1J/view?usp=sharing" target="_blank">
                <button>pdf</button>
            </a>            
        </div>*/}
        <img className="cv" src="assets/CVFlorido.png" alt="" />
        <img className="cvmob" src="assets/CVFlorido2.png" alt="" />
        {/*
        <ActionButton buttonColor="#ffc500" onPress={() => {}} />
        */}
        
    </div>
  )
}
