import React from 'react'
import "./menu.scss"

export default function Menu({menuOpen, setMenuOpen}) {
    return (
        <div className={"menu "+(menuOpen && "active")}>
            <ul>
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#intro"> Inicio </a>
                </li>
            {/*
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#portfolio"> Portfolio </a>
                </li>
            */}
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#divCV"> Currículum </a>
                </li>
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#works"> Trabajos </a>
                </li>
            {/*
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#testimonials"> Testimonials </a>
                </li>
            */}
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#contact"> Contacto </a>
                </li>
            </ul>
            <a className='pdf' href="https://drive.google.com/file/d/1aZbSZ-lXVx-RpugRS2tkuOWkFKLb3b1J/view?usp=sharing" target="_blank">
                <button>CV</button>
            </a>
        </div>
    )
}
