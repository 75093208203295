import './works.scss'
import { useEffect, useState, useContext, React} from 'react';
import {ArrowBackIos} from '@material-ui/icons';
import { ThemeContext } from '../context';


export default function Works() {

    const [currentSlide, setCurrentSlide] = useState(0)
    const theme = useContext(ThemeContext)
    const darkMode = theme.state.darkMode

    var path = process.env.PUBLIC_URL;
    var icon1 = "assets/barkido.png";
    var icon2 = "assets/barkidoW.png";
    var image1 = "assets/barkidoWeb.jpeg";
    var image2 = "assets/barkidoApp2.png";
    var image3 = "assets/barkidoTeam.jpg";
    const data =[
        {
            id: "1",
            icon: path+icon1,
            title: "Web Design",
            desc: "Barkido es una app comprometida con el bienestar animal y promueve la interacción positiva entre los animales y los seres humanos.¿Y tu, ya formas parte de Barkido?",
            img: path+image1
        },
        {
            id: "2",
            icon: path+icon2,
            title: "Mobile Application",
            desc: "Barkido es una nueva red social centrada en los animales de compañía.",
            img: path+image2
        },
        {
            id: "3",
            icon: path+icon1,
            title: "Start Up Team",
            desc: "El equipo Barkido ha sido nombrado como el equipo ganador de AppsFactory 2020",
            img: path+image3
        }
    ] 

    const handleClick = (way)=>{
        way === "left" ? 
            setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 2) 
            : setCurrentSlide(currentSlide < data.length -1 ? currentSlide+1 : 0);
    }
    return (
        <div className="works" id="works">
            <div className="slider" style={{transform:`translateX(-${currentSlide * 100}vw)`}}>
                {data.map((d)=>(
                    <div className="container">
                        <div className="item" style={{backgroundColor: darkMode && "#333"}}>
                            <div className="left">
                                <div className="leftContainer">
                                    <div className="imgContainer">
                                        <img src={d.icon} alt="" />
                                    </div>
                                    <h2>{d.title}</h2>
                                    <p>
                                        {d.desc}
                                    </p>
                                    <a className='link-barkido' href="https://barkido.com">www.barkido.com</a>
                                </div>
                            </div>
                            <div className="right">
                                <img src={d.img} alt="" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ArrowBackIos className="arrow left" onClick={()=>handleClick("left")}/>
            <ArrowBackIos className="arrow right" onClick={()=>handleClick()}/>

        </div>
    )
}
